<template>
  <app-form-view
    multipart
    app="propertyAndAgreement"
    model="masterpropertyandagreementheader"
    api-url="property-and-agreement/master-property-and-agreement-header/"
    :title="$t('menu.masterPropertyAndAgreementHeader')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" md="6" class="app-input--approve-wrapper">
          <app-input
            name="propertyAndAgreementHeadername"
            rules="required"
            :label="$t('fields.tabName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approvepropertyAndAgreementHeadername"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_propertyAndAgreementHeader_name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
      <v-card v-if="mode !== 'create'">
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>Customer Companies</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  ref="table"
                  app="propertyAndAgreement"
                  model="masterpropertyandagreementdetail"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="headers"
                  :serverItems="masterPropertyAndAgreementDetailItems"
                  :clientItems="masterPropertyAndAgreementDetailItems"
                  :loading="loading"
                  :server-items-length="
                    masterPropertyAndAgreementDetailItemsLength
                  "
                  v-model="masterPropertyAndAgreementDetailItemsSelected"
                  @server="getMasterPropertyAndAgreementDetailItems"
                  @client="getMasterPropertyAndAgreementDetailClientItems"
                  @delete="onDeleteItem"
                  @edit="onEdit"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title> Name </v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <v-dialog
                        v-model="dialogMasterPropertyAndAgreementDetail"
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <slot name="actions">
                            <v-btn
                              v-show="
                                mode === 'edit' &&
                                masterPropertyAndAgreementDetailItemsLength ===
                                  0
                              "
                              dark
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              New Item
                            </v-btn>
                            <v-btn
                              v-show="
                                masterPropertyAndAgreementDetailItemsSelected.length &&
                                mode === 'edit'
                              "
                              color="error"
                              @click="
                                onDelete(
                                  masterPropertyAndAgreementDetailItemsSelected
                                )
                              "
                            >
                              {{ $t('btn.delete') }}
                            </v-btn>
                          </slot>
                        </template>
                        <v-card>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    name="customer_company_id"
                                    type="chips-server"
                                    rules="required"
                                    :label="$t('fields.customerCompanyName')"
                                    :view="view"
                                    :binds="{
                                      apiUrl: `customer-company/customer_company/`,
                                      chip: {
                                        color: 'secondary',
                                        icon: 'mdi-account-circle-outline',
                                        value: 'name',
                                        router: customerCompanyRouter
                                      }
                                    }"
                                    v-model="editedItem.customer_company_id"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeMasterPropertyAndAgreementDetail"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="saveMasterPropertyAndAgreementDetail"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppTable from '@components/AppTable.vue'

export default {
  name: 'masterpropertyandagreement-form',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  data() {
    return {
      tab: null,
      loading: false,
      formData: {
        state: 'draft'
      },
      editedItem: {
        property_and_agreement_header_id: null,
        customer_company_id: null
      },
      approveItems: ['approve_propertyAndAgreementHeader_name'],
      masterPropertyAndAgreementDetailItems: [],
      masterPropertyAndAgreementDetailItemsSelected: [],
      masterPropertyAndAgreementClientItems: [],
      masterPropertyAndAgreementDetailItemsLength: 0,
      dialogMasterPropertyAndAgreementDetail: false,
      apiMethod: 'post'
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    headers() {
      return [
        {
          text: this.$t('fields.customerCompanyName'),
          value: 'customer_company_id',
          chips: {
            color: 'secondary',
            icon: 'mdi-account-circle-outline',
            value: 'name'
          },
          hideFilter: true,
          groupable: false,
          sortable: false
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  methods: {
    customerCompanyRouter(item) {
      return {
        name: 'customerCompanyEdit',
        params: { id: item.id }
      }
    },
    getMasterPropertyAndAgreementDetailItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        const ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          property_and_agreement_header_id: this.$route.params.id
        }
      } else {
        this.params = {
          property_and_agreement_header_id: this.$route.params.id
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'property-and-agreement/master-property-and-agreement-detail/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.masterPropertyAndAgreementDetailItemsLength = data.count
          this.masterPropertyAndAgreementDetailItems = data.results
          this.masterPropertyAndAgreementClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getMasterPropertyAndAgreementDetailClientItems(multiSearch) {
      this.masterPropertyAndAgreementClientItems =
        this.masterPropertyAndAgreementDetailItems.filter((item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        })
    },
    closeMasterPropertyAndAgreementDetail() {
      this.editedItem = {}
      this.dialogMasterPropertyAndAgreementDetail = false
    },
    saveMasterPropertyAndAgreementDetail() {
      this.loading = true

      this.editedItem.property_and_agreement_header_id = this.$route.params.id

      const data = this.editedItem
      data.customer_company_id = this.editedItem.customer_company_id
      data.customer_company_id = data.customer_company_id.map(
        (value) => value.id
      )

      this.$api({
        method: this.apiMethod,
        url: `property-and-agreement/master-property-and-agreement-detail/${
          this.editId ? this.editId + '/' : ''
        }`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeMasterPropertyAndAgreementDetail()
      this.getMasterPropertyAndAgreementDetailItems()
    },
    onEdit(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogMasterPropertyAndAgreementDetail = true
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `property-and-agreement/master-property-and-agreement-detail/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    prepareData(data) {
      // data.property_and_agreement_header_id =
      //   data.property_and_agreement_header_id.id
      // data.customer_company_id = data.customer_company_id.map(
      //   (value) => value.id
      // )
      return data
    }
  }
}
</script>
